.react-calendar {
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-around;
  max-width: 97%;
  height:99%;
  background:white;
  border: 3px solid black;
  line-height: 1.125em;
  font-size:1.2rem;
  box-shadow: 0 12px 24px #00000080;
  padding:0.8rem;
  border-radius:20px;
  background-image :url('/public/img/neighbor.svg');
  background-repeat:no-repeat;
  background-size:cover;
  opacity:0.95;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* 날짜 */
.react-calendar button {
  padding:0.6rem;
  border:none;
  font-size: 1.2rem;
  font-weight:bold;
  height:75px;


}
.react-calendar button:enabled:hover {
  /* cursor: pointer; */
}
.react-calendar__navigation {
  display: flex;
  height: 44px;

}

/* 상단내비게이션 */
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-weight:bold;
  background: none;
  font-size: 1.2rem;
  padding-bottom:10px;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

/* 요일 */
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9rem;


}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;

}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;

}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;

}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  background: none;
  display:flex;
  align-items:center;
  justify-content:space-between;
  flex-direction:column;
  line-height:25px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
/* .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background-color: #e6e6e6; 
} */
.react-calendar__tile--now {
  background: lightgray;
  border-radius:10px;
  color: white;
  
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /* background: white; */
  border-radius:10px;


}
.react-calendar__tile--hasActive {
  /* background: lightgray; */
  border-radius:10px;

}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  /* background: lightgray; */
  border-radius:10px;


}
.react-calendar__tile--active {
  /* background: lightgray; */
  /* color: white; */
  border-radius:10px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  /* background: lightgray; */
  border-radius:10px;


}
.react-calendar--selectRange .react-calendar__tile--hover {
  /* background: lightgray; */
  border-radius:10px;

}

.image {
  display:inline;
  height: 35px;
  width: 35px;
  border-radius: 15%;
  display: flex;
  background:wheat;
  padding:2px;
}